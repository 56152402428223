<template>
  <side-content
    modal
    hide-submit
    :title="titleModal"
    @close="closeModal">
    <loading v-if="loading > 0" class="absolute-center" />
    <template v-else>
      <v-container grid-list-lg>
        <v-form ref="form" v-model="valid">
          <v-jsf v-model="model" :schema="schema" :options="formOptions">
            <template slot="recipient-document" slot-scope="slotProps">
              <investigator-by-document :label="slotProps.label" :value="slotProps.value" :on="slotProps.on" @investigator="setInvestigator" @blur="setInvestigator" />
            </template>
            <template slot="recipient-birthDate" slot-scope="{ value, label, on }">
              <v-text-field
                v-mask="mask.date"
                :value="value"
                :label="label"
                :rules="dateRules"
                filled
                masked
                v-on="on" />
            </template>

            <template slot="recipient-name" slot-scope="{ value, label, on, rules }">
              <v-text-field
                :value="value"
                :label="label"
                :rules="rules"
                filled
                v-on="on" />
            </template>

            <template slot="recipient-email" slot-scope="{ value, label, on }">
              <v-combobox
                :value="value"
                :items="emails"
                :label="label"
                autocomplete="new-email"
                small-chips
                deletable-chips
                filled
                v-on="on"
                @change="model.recipient.email = $event.value || $event" />
            </template>
            <template slot="tickets-items" slot-scope="slotProps">
              <template v-for="(ticket, index) of slotProps.value">
                <h4 :key="`ticket-title-${index}`" class="d-flex">
                  {{ zeroPad(index + 1, 2) }}
                  <v-spacer />
                  <v-btn text small @click="toggleIgnoreOnInitialProposal(ticket, slotProps.schema)">
                    <span v-if="ticket.ignoreOnInitialProposal">Aceitar título</span>
                    <span v-else>Ignorar título</span>
                  </v-btn>
                </h4>
                <v-jsf :key="index" v-model="model.tickets.items[index]" :class="ticket.ignoreOnInitialProposal && 'disabled'" :schema="slotProps.schema.items" :options="formOptions">
                  <template slot="originalValue" slot-scope="{ label, on }">
                    <v-number-field
                      required
                      currency
                      :disabled="ticket.ignoreOnInitialProposal"
                      not-replace
                      :label="label"
                      class="proposal-value"
                      v-on="on"
                      @change="setOriginalValue(ticket, $event)" />
                  </template>

                  <template slot="description" slot-scope="{ label, value, on }">
                    <v-text-field
                      :value="value"
                      :disabled="ticket.ignoreOnInitialProposal"
                      :label="label"
                      filled
                      v-on="on" />
                  </template>

                  <template slot="id" slot-scope="{ label, value, on }">
                    <v-text-field
                      :value="value"
                      :disabled="ticket.ignoreOnInitialProposal"
                      :label="label"
                      filled
                      v-on="on" />
                  </template>
                </v-jsf>
              </template>

              <v-btn block outlined @click="addTicket">
                Adicionar mais um título
              </v-btn>
            </template>
          </v-jsf>
        </v-form>
      </v-container>
    </template>
    <template v-slot:footer>
      <v-btn color="primary" block @click="createCase(model)">
        Cadastrar
      </v-btn>
    </template>
  </side-content>
</template>

<script>
  const FormAddress = () => import('@/components/ui/forms/FormAddress')
  const FormUser = () => import('@/components/ui/forms/FormUser')
  const InvestigatorByDocument = () => import('@/components/ui/inputs/InvestigatorByDocument')
  const DatePicker = () => import('@/components/ui/DatePicker')
  const EmptyState = () => import('@/components/ui/EmptyState')
  const Loading = () => import('@/components/ui/Loading')
  const SelectEvents = () => import('@/components/ui/SelectEvents')
  const SelectEmails = () => import('@/components/ui/SelectEmails')

  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import resizing from '@/mixins/resizing'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CasesNew',
    components: {
      FormAddress,
      FormUser,
      DatePicker,
      EmptyState,
      Loading,
      SelectEvents,
      SelectEmails,
      InvestigatorByDocument
    },
    mixins: [ validate, masks, resizing ],
    data: () => ({
      emails: [],
      model: {
        eventId: null,
        // enableIgnoreTicketsOnInitialProposal: true,
        tickets: {
          items: []
        },
        recipient: {
          type: 'pf',
          document: null,
          propertyRegistration: null,
          birthDate: null,
          email: null,
          name: null,
          phone: null,
          address: {}
        }
      },
      schema: {
        type: 'object',
        properties: {
          required: [
            'eventId'
          ],
          eventId: {
            type: 'string',
            title: 'Campanha',
            oneOf: [],
            'x-props': {
              placeholder: 'Selecione uma opção',
              loading: true
            }
          },
          recipient: {
            type: 'object',
            title: 'Dados do convidado',
            readOnly: true,
            required: [
              'document',
              'name',
              'birthDate',
              'email'
            ],
            properties: {
              document: {
                type: 'string',
                title: 'CPF',
                readOnly: true
              },
              name: {
                type: 'string',
                title: 'Nome',
                readOnly: true
              },
              birthDate: {
                type: 'string',
                title: 'Data de nascimento',
                readOnly: true
              },
              email: {
                type: 'string',
                title: 'E-mail',
                readOnly: true
              },
              phone: {
                type: 'string',
                title: 'Telefone',
                readOnly: true
              },
              address: {
                type: 'object',
                title: 'Endereço',
                readOnly: true,
                required: [
                  'zipCode',
                  'address',
                  'number',
                  'neighborhood',
                  'city',
                  'uf'
                ],
                properties: {
                  zipCode: {
                    type: 'string',
                    title: 'CEP'
                  },
                  address: {
                    type: 'string',
                    title: 'Endereço'
                  },
                  number: {
                    type: 'string',
                    title: 'Número'
                  },
                  complement: {
                    type: 'string',
                    title: 'Complemento'
                  },
                  neighborhood: {
                    type: 'string',
                    title: 'Bairro'
                  },
                  city: {
                    type: 'string',
                    title: 'Cidade'
                  },
                  uf: {
                    type: 'string',
                    title: 'Estado'
                  }
                }
              }
            }
          },
          tickets: {
            type: 'object',
            title: 'Titulos',
            readOnly: true,
            properties: {
              items: {
                type: 'array',
                title: 'Tickets',
                'x-display': 'list',
                items: {
                  type: 'object',
                  required: [
                    'dueAt',
                    'originalValue'
                  ],
                  'x-class': 'pa-0',
                  properties: {
                    dueAt: {
                      type: 'string',
                      format: 'date',
                      title: 'Vencimento'
                    },
                    originalValue: {
                      type: 'number',
                      title: 'Valor Original'
                    },
                    description: {
                      type: 'string',
                      title: 'Descrição'
                    },
                    id: {
                      type: 'string',
                      title: 'Identificação'
                    }
                  }
                }
              }
            }
          }
        }
      },
      formOptions: {
        fieldProps: {
          filled: true
        },
        selectProps: {
          clearable: true
        },
        messages: {
          required: 'Campo obrigatório'
        },
        sectionsClass: 'formSection',
        childrenClass: 'pa-0',
        hideReadOnly: true,
        removeAdditionalProperties: false,
        icons: {
          calendar: ''
        }
      },
      options: { suppressScrollX: true, wheelPropagation: false },
      openModal: false,
      showModalConfirm: false,
      isEditCase: false,
      titleModal: 'Cadastrar Caso',
      valid: null,
      investigator: {}
    }),
    computed: {
      ...mapGetters({
        events: 'events/list',
        loading: 'events/listLoading',
        isLoading: 'app/getLoading',
        event: 'events/record'
      }),
      showPhone () {
        return this.event && this.event.sms.send
      },
      showAddress () {
        return this.event && this.event.directNegotiation && this.event.directNegotiation.has || (this.event && this.event.letter.send) || (this.event && this.event.payment && this.event.payment.pagarme)
      }
    },
    watch: {
      'model.eventId' (eventId) {
        eventId && this.$store.dispatch('events/get', eventId)
      },
      events (value) {
        this.schema.properties.eventId.oneOf = value.map(item => ({ const: item.id, title: item.name }))
        this.schema.properties.eventId['x-props'].loading = false
      },
      event (event)  {
        this.schema.properties.recipient.properties.phone.readOnly = !event.sms.send
        this.schema.properties.recipient.properties.address.readOnly = !event.letter.send

        this.schema.properties.recipient.properties.document.readOnly = false
        this.schema.properties.recipient.readOnly = false
        this.schema.properties.tickets.readOnly = false
      },
    },
    mounted () {
      this.$store.dispatch('events/getList', { page: 1, rowsPerPage: -1 })
      // this.addTicket()
      this.showModal()
    },
    methods: {
      showModal () {
        this.openModal = true
        this.isEditCase = false
      },
      closeModal () {
        this.openModal = false
        this.$nextTick(() => {
          this.$router.push({ name: 'Cases' })
        })
      },
      setInvestigator (data) {
        this.emails = data.emails.map(item => ({ text: item.email, value: item.email, disabled: item.ignored  }))
        this.model.recipient.name = data.name
        this.model.recipient.birthDate = new Date(data.birthDate).toISOString().substring(0, 10).replace(/-/g, '/').split('/').reverse().join('/')

        this.schema.properties.recipient.properties.name.readOnly = false
        this.schema.properties.recipient.properties.birthDate.readOnly = false
        this.schema.properties.recipient.properties.email.readOnly = false
        // this.$nextTick(() => { this.investigator = data })
      },
      submitStep2 () {
        const isUserValid = this.$refs.formStep2_1.$children[0].validate()
        const isAddressValid = this.showAddress && this.$refs.formStep2_2.$children[0].validate()
        const isValid = this.showAddress ? isUserValid && isAddressValid : isUserValid

        if (isValid) {
          if (this.event.calculate === 'giap') {
            this.model.tickets = this.newCase.recipient.tickets
            this.newCase.details = this.newCase.recipient.details
            this.newCase.currentValue = this.newCase.recipient.currentValue
            this.newCase.originalValue = this.newCase.recipient.originalValue
            delete this.newCase.recipient.tickets
          }

          this.step = 3
        }
      },
      createCase (data) {
        data = data.clone()
        data.tickets = data.tickets.items

        if (this.$refs.form.validate()) {
          this.$store.commit('app/setLoading', true)

          this
            .$store
            .dispatch('cases/create', data)
            .then(this._success)
            .catch(this._failure)
        } else {
          this.$root.$emit('snackbar', {show: true, time: 10000, color: 'warning', text: 'Preencha os campos obrigatórios'})
        }
      },
      parseTicket(ticket) {
        let originalValue = parseFloat(ticket.originalValue.replace('.', '').replace(',', '.'))
        let ticketClone = { ...ticket }
        return Object.assign({}, ticket, { originalValue })
      },
      prevStep () {
        this.step = this.step - 1
      },
      removeTicket (ticket) {
        this.model.tickets = this.model.tickets.filter(record => record !== ticket)
      },
      addTicket () {
        let date = new Date()
        this.model.tickets.items.push({ dueAt: null, originalValue: null, description: null, id: null, ignoreOnInitialProposal: false })
      },
      _success () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Caso criado com sucesso'})
        this.$router.push({ name: 'Cases' })
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
      showAddTicket (index) {
        return this.model.tickets.length === index + 1
      },
      selectedEvent (event) {
        this.newCase.eventId = event.id
        this.newCase.enableIgnoreTicketsOnInitialProposal = event.calculateParams.enableIgnoreTicketsOnInitialProposal
      },
      createEvent () {
        this.$nextTick(this.$router.push({name: 'EventNew', query: { returnTo: 'CaseNew' }}))
      },
      zeroPad (number, digits) {
        let num = number + ''
        while (num.length < digits) { num = '0' + num }
        return num
      },
      toggleIgnoreOnInitialProposal (ticket, schema) {
        // console.log(schema)

        if (ticket.ignoreOnInitialProposal) {
          ticket.ignoreOnInitialProposal = false

        }  else {
          ticket.ignoreOnInitialProposal = true
        }
      },
      setOriginalValue(obj, event) {
        obj.originalValue = event.value
      }
    }
  }
</script>

<style lang="sass">
  .case-new
    position: relative
    height: 400px
  .vjsf-property-tickets
    .disabled
      position: relative
      pointer-events: none
      opacity: 0.5
</style>

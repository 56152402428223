var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-content',{attrs:{"modal":"","hide-submit":"","title":_vm.titleModal},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.createCase(_vm.model)}}},[_vm._v(" Cadastrar ")])]},proxy:true}])},[(_vm.loading > 0)?_c('loading',{staticClass:"absolute-center"}):[_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-jsf',{attrs:{"schema":_vm.schema,"options":_vm.formOptions},scopedSlots:_vm._u([{key:"recipient-document",fn:function(slotProps){return [_c('investigator-by-document',{attrs:{"label":slotProps.label,"value":slotProps.value,"on":slotProps.on},on:{"investigator":_vm.setInvestigator,"blur":_vm.setInvestigator}})]}},{key:"recipient-birthDate",fn:function(ref){
var value = ref.value;
var label = ref.label;
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask.date),expression:"mask.date"}],attrs:{"value":value,"label":label,"rules":_vm.dateRules,"filled":"","masked":""}},on))]}},{key:"recipient-name",fn:function(ref){
var value = ref.value;
var label = ref.label;
var on = ref.on;
var rules = ref.rules;
return [_c('v-text-field',_vm._g({attrs:{"value":value,"label":label,"rules":rules,"filled":""}},on))]}},{key:"recipient-email",fn:function(ref){
var value = ref.value;
var label = ref.label;
var on = ref.on;
return [_c('v-combobox',_vm._g({attrs:{"value":value,"items":_vm.emails,"label":label,"autocomplete":"new-email","small-chips":"","deletable-chips":"","filled":""},on:{"change":function($event){_vm.model.recipient.email = $event.value || $event}}},on))]}},{key:"tickets-items",fn:function(slotProps){return [_vm._l((slotProps.value),function(ticket,index){return [_c('h4',{key:("ticket-title-" + index),staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.zeroPad(index + 1, 2))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.toggleIgnoreOnInitialProposal(ticket, slotProps.schema)}}},[(ticket.ignoreOnInitialProposal)?_c('span',[_vm._v("Aceitar título")]):_c('span',[_vm._v("Ignorar título")])])],1),_c('v-jsf',{key:index,class:ticket.ignoreOnInitialProposal && 'disabled',attrs:{"schema":slotProps.schema.items,"options":_vm.formOptions},scopedSlots:_vm._u([{key:"originalValue",fn:function(ref){
var label = ref.label;
var on = ref.on;
return [_c('v-number-field',_vm._g({staticClass:"proposal-value",attrs:{"required":"","currency":"","disabled":ticket.ignoreOnInitialProposal,"not-replace":"","label":label},on:{"change":function($event){return _vm.setOriginalValue(ticket, $event)}}},on))]}},{key:"description",fn:function(ref){
var label = ref.label;
var value = ref.value;
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":value,"disabled":ticket.ignoreOnInitialProposal,"label":label,"filled":""}},on))]}},{key:"id",fn:function(ref){
var label = ref.label;
var value = ref.value;
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":value,"disabled":ticket.ignoreOnInitialProposal,"label":label,"filled":""}},on))]}}],null,true),model:{value:(_vm.model.tickets.items[index]),callback:function ($$v) {_vm.$set(_vm.model.tickets.items, index, $$v)},expression:"model.tickets.items[index]"}})]}),_c('v-btn',{attrs:{"block":"","outlined":""},on:{"click":_vm.addTicket}},[_vm._v(" Adicionar mais um título ")])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }